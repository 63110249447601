import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import axios from 'axios';
import config from '../../config.js';
import { store } from '../../store/index.js';

export function getScanAndPayCartDetailByPhone(phone) {
    const headers = {};
    const state = store.getState();
    if (state.user.token) {
        headers.authorization = state.user.token;
    }
    return axios
        .get(`${config.pharmacyService}/scan-and-pay/fetch-cart-detail?phone=${phone}`, {
            headers,
        })
        .then((resp) => {
            const responseBody = resp.data;
            return responseBody;
        })
        .catch((err) => {
            throw err;
        });
}

export function updateCartApprovedAmount(cartId, approvedAmount) {
    const headers = {
        'Content-Type': 'application/json',
    };
    const state = store.getState();
    if (state.user.token) {
        headers.Authorization = state.user.token;
    }
    return axios
        .post(`${config.pharmacyService}/scan-and-pay/update-cart-approved-amount`, {
            cartId,
            approvedAmount,
        }, { headers })
        .then((resp) => resp.data)
        .catch((err) => {
            throw err;
        });
}

function UpdateApprovedAmountModal({ onClose }) {
    const [phone, setPhone] = useState(''); // State for phone input
    const [cartDetails, setCartDetails] = useState({
        items: { covered: [], uncovered: [] },
        totalAmount: 0,
        requestedAmount: 0,
        approvedAmount: 0,
        prescriptionUrl: '',
        cartId: null,
    });
    const [approvedAmount, setApprovedAmount] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);

    // Fetch cart details when the phone number is submitted
    const fetchCartDetails = async (phone) => {
        setLoading(true);
        try {
            const data = await getScanAndPayCartDetailByPhone(phone);
            setCartDetails(data.cart);
            setApprovedAmount(data.cart.approvedAmount);
        } catch (err) {
            setError('Failed to fetch cart details. Please try again.');
        }
        setLoading(false);
    };

    const handlePhoneSubmit = (e) => {
        e.preventDefault();
        if (phone) {
            fetchCartDetails(phone);
        } else {
            setError('Please enter a valid phone number');
        }
    };

    // Update approved amount
    const handleApprovedAmountSubmit = async () => {
        if (!approvedAmount || isNaN(approvedAmount)) {
            setError('Please enter a valid approved amount');
            return;
        }

        setUpdating(true);
        try {
            await updateCartApprovedAmount(cartDetails.cartId, approvedAmount);
            setError('');
            fetchCartDetails(phone); // Refresh the cart details after update
        } catch (err) {
            setError('Failed to update approved amount. Please try again.');
        }
        setUpdating(false);
    };

    return (
        <Modal isOpen={true} style={{ maxWidth: '1400px', width: '100%', borderRadius: '4px', backgroundColor: '#f8f9fa' }}>
            <ModalHeader style={{ backgroundColor: '#8752CC' }} toggle={onClose}>
                <h4 className="text-center" style={{ color: '#fff', fontWeight: 'bold' }}>Scan & Pay Update Approved Amount</h4>
            </ModalHeader>
            <ModalBody style={{ padding: '20px 40px' }}>
                {error && <p className="text-danger text-center">{error}</p>}

                {/* Phone input field */}
                <form onSubmit={handlePhoneSubmit} className="form-group">
                    <label htmlFor="phone"><h5>Enter Phone Number:</h5></label>
                    <input
                        type="text"
                        id="phone"
                        className="form-control"
                        value={phone}
                        style={{ width: '200px', fontSize: '24px', fontWeight: 'bold', color: 'black' }}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                    />
                    <button type="submit" className="btn btn-primary mt-3 btn-lg" disabled={loading}>
                        {loading ? <Spinner size="lg" /> : 'Fetch Cart Details'}
                    </button>
                </form>

                {/* Loading spinner */}
                {loading && <div className="text-center"><Spinner /></div>}

                {/* Display cart details in separate tables */}
                {!loading && (
                    <>
                        {cartDetails.prescriptionUrl && (
                            <div className="text-center form-group mt-3">
                                <h5>
                                    <a
                                        href={cartDetails.prescriptionUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#007bff' }} // Adjust color as needed
                                    >
                                        View Prescription
                                    </a>
                                </h5>
                            </div>
                        )}

                        {/* Covered Items Table */}
                        {cartDetails.items.covered.length > 0 && (
                            <div className="cart-details mt-4">
                                <h5 className="text-center">Covered Items</h5>
                                <table className="table table-bordered table-striped">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th>Drug Name</th>
                                            <th>MRP (₹)</th>
                                            <th>Quantity</th>
                                            <th>Is Covered</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cartDetails.items.covered.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.name}</td>
                                                <td>{item.mrp}</td>
                                                <td>{item.quantity}</td>
                                                <td>{item.isCovered ? 'Yes' : 'No'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}

                        {/* Uncovered Items Table */}
                        {cartDetails.items.uncovered.length > 0 && (
                            <div className="cart-details mt-4">
                                <h5 className="text-center">Uncovered Items</h5>
                                <table className="table table-bordered table-striped">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th>Drug Name</th>
                                            <th>MRP (₹)</th>
                                            <th>Quantity</th>
                                            <th>Is Covered</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cartDetails.items.uncovered.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.name}</td>
                                                <td>{item.mrp}</td>
                                                <td>{item.quantity}</td>
                                                <td>{item.isCovered ? 'Yes' : 'No'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}

                        {/* Cart Summary */}
                        <div className="text-center summary-details">
                            <h5><strong>Total Amount:</strong> ₹{cartDetails.totalAmount}</h5>
                            <h5><strong>Requested Amount:</strong> ₹{cartDetails.requestedAmount}</h5>

                            {/* Approved Amount Input */}
                            <div className="form-group">
                                <label htmlFor="approvedAmount"><h5><strong>Approved Amount:</strong> ₹{cartDetails.approvedAmount}</h5></label>
                                <div className="d-flex justify-content-center">
                                    <input
                                        type="text"
                                        id="approvedAmount"
                                        className="form-control"
                                        style={{ width: '200px', fontSize:'24px', fontWeight: 'bold', color: 'red' }}
                                        value={approvedAmount}
                                        onChange={(e) => setApprovedAmount(e.target.value)}
                                    />
                                </div>
                            </div>
                            <button onClick={handleApprovedAmountSubmit} className="btn btn-success btn-lg" disabled={updating}>
                                {updating ? <Spinner size="lg" /> : 'Update Approved Amount'}
                            </button>
                        </div>
                    </>
                )}

                <div className="text-right mt-4">
                    <button onClick={onClose} className="btn btn-lg">Close</button>
                </div>
            </ModalBody>
        </Modal>
    );
}

const mapStateToProps = (state) => ({
    authToken: state.user.authToken,
    user: state.user,
});

export default connect(mapStateToProps)(UpdateApprovedAmountModal);
